import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../sections/Navbar';
import Stats from '../sections/Stats';
import MintNFT from '../sections/MintNFT';
import APRBoost from '../sections/APRBoost';
import Stake from '../sections/Stake';
import Information from '../sections/Information';
import LaunchingSoon from '../sections/LaunchingSoon';
import EnterMiner from '../sections/EnterMiner';
import Disclaimer from '../sections/Disclaimer';


const useStyles = makeStyles((theme) => {
  return {
    root: {
        flexGrow: 1
    },
    disabled: {
      pointerEvents: 'none'
    }
  }
});

function Main() {
    const classes = useStyles();
    return (
        <div className={ classes.root }>
          <Navbar/>
          <LaunchingSoon/>
          <EnterMiner/>
          <Stats/>
          <MintNFT/>
          <Stake/>
          <APRBoost/>
          <Information/>
          <Disclaimer/>
        </div>    
    );
  }
  
export default Main;