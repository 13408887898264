import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import bronzeWiz from "../images/bronze_wiz.png";
import silverWiz from "../images/silver_wiz.png";
import goldWiz from "../images/gold_wiz.png";

import { useGetMaxWizLevelOfUser } from '../hooks/stakeHooks';


const textColorContrastText = "#c471ed"
const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundImage: "linear-gradient(to bottom right, #4A00E0, #8E2DE2)",
          paddingTop: 8,
          paddingBottom: "5%",
          color: theme.palette.getContrastText(textColorContrastText)
      },
      mintTitle: {
          padding: theme.spacing(4, 8, 4)
      },
      wizOwned: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: "10%",
        backgroundImage: "linear-gradient(to right, #e0eafc, #cfdef3)"
      },
      head: {
          textAlign: "center",
      },
      cardSubheader: {
        color: theme.palette.success.main,
      },
      cardActionArea: {
          width: "100%",
          paddingBottom: 0
      },
      hideDisplay: {
          display: "none"
      }
    }
});

function APRBoost() {
    const classes = useStyles();

    let maxWizLevelUser = useGetMaxWizLevelOfUser();

    const [aprBoost, setAprBoost] = useState(undefined);
    const [wizImage, setWizImage] = useState(bronzeWiz);
    const [wizTitle, setWizTitle] = useState("Bronze Wizard");

    if ((maxWizLevelUser !== undefined) && (aprBoost === undefined)) {
        maxWizLevelUser = maxWizLevelUser.toString();
        if (maxWizLevelUser === "1") {
            setAprBoost("182.5");
            setWizImage(bronzeWiz);
            setWizTitle("Bronze Wizard");
        }
        else if (maxWizLevelUser === "2") {
            setAprBoost("365");
            setWizImage(silverWiz);
            setWizTitle("Silver Wizard");
        }
        else if (maxWizLevelUser === "3") {
            setAprBoost("547.5");
            setWizImage(goldWiz);
            setWizTitle("Gold Wizard");
        }
    }

    
    return (
        <div className={ classes.container }>
            <Grid container direction="column" justifyContent="center"  alignItems="center" className={classes.mintTitle}>
                <Typography variant="h5" align="center" component="p">
                APR Boost is calculated based on the highest level Wizard you own
                </Typography>
                <Typography variant="h5" align="center" component="p" gutterBottom>
                Your current APR Boost: +{aprBoost? aprBoost: "0"}%
                </Typography>
                <Typography variant="h5" align="center" component="p">
                Highest level Wizard you own: 
                </Typography>
                <Box display={aprBoost? "none": "flex"}>
                <Typography variant="h5" align="center" component="p">
                    You don't own any wizards
                </Typography>
                </Box>
                <Grid item lg={4} md={6} sm={8}>
                    <Card className={aprBoost? classes.wizOwned: classes.hideDisplay}>
                        <CardHeader
                            className={ classes.head }
                            title={wizTitle}
                            subheader={<Typography className={ classes.cardSubheader }>+{aprBoost}% APR Boost</Typography>}
                        />
                        <Box 
                        component="img"
                        alt="MIM Machine Logo"
                        src={wizImage}
                        height="auto"
                        width="80%"
                        />
                    </Card>
                </Grid>                
            </Grid>
        </div>    
    );
  }
  
export default APRBoost;