import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#91B2E2",          
        paddingTop: 8,
        paddingBottom: "5%"
      },
      mintTitle: {
          padding: theme.spacing(4, 8, 6)
      },
      card: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
      },
      wizOwned: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: "20%",
      },
      head: {
          textAlign: "center",
      },
      cardActionArea: {
          width: "100%",
          paddingBottom: 0
      }
    }
});


function Information() {
    const classes = useStyles();


    return (
        <div className={ classes.container } id="info">
            <Grid container direction="column" justifyContent="center"  alignItems="center" className={classes.mintTitle}>
                <Grid item>
                    <Grid item>
                        <Card className={ classes.card }>
                            <CardHeader
                                className={ classes.head }
                                title={<strong>WHAT IS THE MIM STAR MINER</strong> }
                            />
                            <CardContent>
                            <Typography variant='body1' component='h6'>
                                
                                The <strong>MIM Star Miner</strong> is a Stablecoin Crops Fork which yields up to 8% Daily ROI on your MIM (Magic Internet Money).<br/><br/>

                                <strong>What Makes Us Special?</strong> <br/><br/>
                                1. <strong>NFTs</strong>. The higher the Wizard NFT level you own, the higher your Max Compound Bonus. The Max Compound Bonuses are as follows:<br/>
                                - Bronze Wizard NFT Minted: 30% Bonus (12 Compounds)
                                <br/>
                                - Silver Wizard NFT Minted: 32.5% Bonus (13 Compounds)
                                <br/>
                                - Gold Wizard NFT Minted: 35% Bonus (14 Compounds)
                                <br/><br/>
                                

                                2. <strong>'The LOOP'</strong>. 'The LOOP' is a never-ending positive-feedback-loop which allows you to rotate your Stablecoins (MIM) between two projects: The MIM Machine & MIM Star Miner.<br/>
                                Similar to the NFTs, the more POTION (the native token of the MIM Machine) you own, the higher your Max Compound Bonus. <br/>
                                This encourages users to use their Miner profits in the Machine, and the other way around. <br/><br/> 
                                
                                <strong>Key Statistics:</strong> <br/><br/>
                                Up to 2920% APR (Up to 8% Daily ROI) <br/>
                                8% Referral Bonus <br/>
                                2.5% Hire Bonus <br/>
                                12 Hour Compound Timer <br/>
                                4 Hour Withdraw Cooldown <br/>
                                48 Hour Reward Accumulation Cut-Off <br/>
                                10 Times Mandatory Compound Enforced <br/>
                                80% Tax For Early Withdrawals (Collecting Stars). <br/><br/><br/>
                              

                               
                                DYOR! There is always a risk of the uncertain with smart contracts. Only use funds you can afford to lose. <br/>
                            </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Information;