import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


const textColorContrastText = "#3494E6"
const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "#36478a",
          padding: theme.spacing(4, 8, 6),
          color: "#11d0ed"
      }
    }
});

function Disclaimer() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start">
                <Typography variant='body2'>Disclaimer: MIM Machine/MIM Star Miner will not accept liability for any losses or damages, including but not limited to, any loss of profit, which may occur directly or indirectly from the use of or reliance on the information presented. 
                MIM Machine/MIM Star Miner does not guarantee the accuracy and or timelines of the information contained herein. There is always the risk of the uncertain with Smart Contracts. Always consult a professional advisor prior to investing in high-risk projects such as this one.</Typography>
            </Grid>
        </div>    
    );
  }
  
export default Disclaimer;