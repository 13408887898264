import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Snackbars from '../components/Snackbars';

import { constants, utils, BigNumber } from "ethers";

import { mim_machine_address } from "../constants/contracts";

import { useGetApyM, useGetUserBUSDStaked, useTotalBUSDStaked, useGetUserUnclaimedTokens_M, useApproveBUSD, useAllowanceBUSD, useStakeBUSD, useClaimToken_M, useBalanceOfBUSD, useUnStakeToken, useGetUserUnclaimedTokens_T, useClaimToken_T, useBalanceOfToken, useGetAPY_T, useGetUserTokenStaked, useTotalTokenStaked, useGetUserTimeToUnstake, useStakeToken, useGetMaxWizLevelOfUser, useGetTokenSoldToday, useGetTokenAvailableToSell, useGetTimeToNextDay, useSellToken, useGetTokenPrice } from '../hooks/stakeHooks';

const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "#91B2E2",
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(5)
      },
      aprBoostColour: {
        color: theme.palette.success.main,
      }
    }
});


function Stake() {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const apyM = useGetApyM();
    const userBUSDStaked = useGetUserBUSDStaked();
    const totalBUSDStaked = useTotalBUSDStaked();
    const unclaimedTokenM = useGetUserUnclaimedTokens_M();
    const busdBalance = useBalanceOfBUSD();
    const [approveMIMSpend, setApproveMIMSpend] = useState(undefined);
    const [useMIMSpend, setUseMIMSpend] = useState(undefined);

    const apyT = useGetAPY_T();
    let maxWizLevelUser = useGetMaxWizLevelOfUser();
    const userTokenStaked = useGetUserTokenStaked();
    const totalTokenStaked = useTotalTokenStaked();
    let userTimeToUnstake = useGetUserTimeToUnstake();
    let userTimeToUnstakeFormatted = undefined;
    const unclaimedTokenT = useGetUserUnclaimedTokens_T();
    const tokenBalance = useBalanceOfToken();
    const [useTokenSpend, setUseTokenSpend] = useState(undefined);
    let aprBoost = undefined;

    const tokenSoldToday = useGetTokenSoldToday();
    const tokenAvailableToSell = useGetTokenAvailableToSell();
    let timeToNextDay = useGetTimeToNextDay();
    let timeToNextDayFormatted = undefined;
    const tokenPrice = useGetTokenPrice();
    const [tokenToBeSold, setTokenToBeSold] = useState(undefined);

    if (userTimeToUnstake !== undefined) {
        userTimeToUnstake = parseInt(userTimeToUnstake.toString());

        var t = userTimeToUnstake + 60				
        var startD = parseInt(t / 60 / 60 / 24)
        
        t = t - startD * 60 * 60 * 24
        var startH = parseInt(t / 60 / 60)	
        
        t = t - startH * 60 * 60
        var startM = parseInt(t / 60)	

        userTimeToUnstakeFormatted = `${startD}D : ${startH}H : ${startM}M`;
    }

    if (timeToNextDay !== undefined) {
        timeToNextDay = parseInt(timeToNextDay.toString());

        timeToNextDayFormatted = new Date(timeToNextDay * 1e3).toISOString().slice(-13, -5);
        timeToNextDayFormatted = timeToNextDayFormatted.split(":");
        timeToNextDayFormatted = timeToNextDayFormatted[0] + "H : " + timeToNextDayFormatted[1] + "M : " + timeToNextDayFormatted[2] + "S"
    }
    
    let userAllowance = useAllowanceBUSD();
    if (userAllowance && approveMIMSpend === undefined) {
        userAllowance = utils.formatEther(userAllowance).slice(0, -2);
        setApproveMIMSpend(userAllowance);
    }

    if ((maxWizLevelUser !== undefined)) {
        maxWizLevelUser = maxWizLevelUser.toString();
        if (maxWizLevelUser === "1") {
            aprBoost = "182.5";
        }
        else if (maxWizLevelUser === "2") {
            aprBoost = "365";
        }
        else if (maxWizLevelUser === "3") {
            aprBoost = "547.5";
        }
    }

    // claim potion
    const { state: useClaimToken_MState, send: claimToken_M, events: useClaimToken_MEvents, resetState: resetUseClaimToken_M } = useClaimToken_M();
    function handleClaimToken_M(){
        claimToken_M().catch(err => {
            setOpen(true);
            setSeverity("warning");
        }); 
    }

    useEffect(() => {
        if (useClaimToken_MState.status === "Success") {
            setOpen(true);
            setSeverity("success");
            resetUseClaimToken_M()
        }
        else if (useClaimToken_MState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (useClaimToken_MState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
    }, [useClaimToken_MState]);

    // approve busd
    const { state: approveBUSDState, send: approveBUSD, events: approveBUSDEvents, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(){        
        approveBUSD(mim_machine_address, utils.parseEther(approveMIMSpend)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            let allowance = approveBUSDEvents[0].args.value;
            allowance = utils.formatEther(allowance).slice(0, -2);
            setApproveMIMSpend(allowance);
            resetApproveBUSD();
        }
    }, [approveBUSDEvents]);

    // get max mim
    function handleMaxMIM() {
        setUseMIMSpend(approveMIMSpend);
    }

    // Use MIM
    const { state: stakeBUSDState, send: stakeBUSD, events: stakeBUSDEvents, resetState: resetStakeBUSD } = useStakeBUSD();
    function handleUseMIM(){
        if(parseInt(useMIMSpend) <= parseInt(approveMIMSpend)) {
            stakeBUSD(utils.parseEther(useMIMSpend)).catch(err => {
                setOpen(true);
                setSeverity("warning");
            });
        }
        else {
            setOpen(true);
            setSeverity("warning")
        }
    }

    useEffect(() => {
        if (stakeBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (stakeBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (stakeBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [stakeBUSDState]);


    // unstake token
    const { state: unStakeTokenState, send: unStakeToken, events: unStakeTokenEvents, resetState: resetUnStakeToken } = useUnStakeToken();
    function handleUnStakeToken(){   
        unStakeToken().catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (unStakeTokenState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (unStakeTokenState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (unStakeTokenState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [unStakeTokenState]);

    // claim token
    const { state: claimToken_TState, send: claimToken_T, events: claimToken_TEvents, resetState: resetClaimToken_T } = useClaimToken_T();
    function handleClaimToken_T(){        
        claimToken_T().catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (claimToken_TState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (claimToken_TState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (claimToken_TState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [claimToken_TState]);

    // get max token
    function handleMaxToken() {
        if (tokenBalance) {
            setUseTokenSpend(parseFloat(utils.formatEther(tokenBalance)).toFixed(2));
        }
    }

    // stake token
    const { state: stakeTokenState, send: stakeToken, events: stakeTokenEvents, resetState: resetStakeToken } = useStakeToken();
    function handleStakeToken(){
        stakeToken(utils.parseEther(useTokenSpend)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (stakeTokenState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (stakeTokenState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (stakeTokenState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [stakeTokenState]);

    // sell token
    const { state: sellTokenState, send: sellToken, events: sellTokenEvents, resetState: resetSellToken} = useSellToken();
    function handleSellToken(){
        sellToken(utils.parseEther(tokenToBeSold)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (sellTokenState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (sellTokenState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (sellTokenState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [sellTokenState]);

    // get max token to be sold
    function handleMaxTokenToBeSold() {
        if (tokenBalance) {
            setTokenToBeSold(parseFloat(utils.formatEther(tokenBalance)).toFixed(2));
        }
    }

    return (
        <div >
            <Grid className={ classes.container } container spacing={1} direction="row" justifyContent="space-evenly">
                <Snackbars open={open} setOpen={setOpen} severity={severity}/>
                <Grid item lg={3}>
                    <Card>
                        <CardHeader title="MINT POTION"/>
                        <CardContent>
                            <Typography variant='body1' component='h6'>
                                Mint POTION by staking your MIM. You can stake as many times as you want. You cannot unstake this MIM.
                            </Typography>
                            <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                                <Typography variant='body1' component='h6'>
                                    APR
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {apyM? apyM.toString(): "0"}%
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" justifyContent="space-between">
                                <Typography variant='body1' component='h6'>
                                    My Stake
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {userBUSDStaked? parseFloat(utils.formatEther(userBUSDStaked)).toFixed() : "0"}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" justifyContent="space-between">
                                <Typography variant='body1' component='h6'>
                                    Total Stake
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {totalBUSDStaked ? parseFloat(utils.formatEther(totalBUSDStaked)).toFixed() : "0"}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" mt={2} justifyContent="space-between">
                                <Box flexDirection="column" display="flex">
                                    <Typography variant='body1' component='h6'>
                                        POTION MINTED
                                    </Typography>
                                    <Typography variant='body1' component='h6'>
                                        {unclaimedTokenM ? parseFloat(utils.formatEther(unclaimedTokenM)).toFixed(4) : "..."}
                                    </Typography>
                                </Box>
                                <Button variant="contained" color="primary" ml={2} onClick={() => handleClaimToken_M()}>
                                    CLAIM
                                </Button>
                            </Box>
                            <Box mt={2}>
                                <Box mb={1}>
                                    <Typography variant='body1' component='h6'>
                                        Approve MIM spend
                                    </Typography>
                                </Box>
                                <Box alignItems="center" display="flex" justifyContent="space-between">
                                    
                                    <TextField
                                        label="Enter Amount" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setApproveMIMSpend(event.target.value)}
                                    />
                                    <Button variant="contained" color="primary" ml={2} onClick={() => handleApproveBUSD()}>
                                        APPROVE
                                    </Button>
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Box mb={1}>
                                    <Typography variant='body1' component='h6'>
                                        There is a $20 Minimum
                                    </Typography>
                                </Box>
                                <Box alignItems="center" display="flex" justifyContent="space-between">
                                    <TextField
                                        label="Enter Amount" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setUseMIMSpend(event.target.value)}
                                        value = {useMIMSpend ? useMIMSpend : ""}
                                    />
                                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                                        <Button onClick={() => handleMaxMIM()}>MAX</Button>
                                        <Button onClick={() => handleUseMIM()}>USE</Button>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                            <Box mt={4}>
                            <Typography variant='body1' component='h6'>
                                MIM Balance: {busdBalance ? parseFloat(utils.formatEther(busdBalance)).toFixed(2) : "0.00"} MIM
                            </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3}>
                <Card>
                    <CardHeader title="STAKE POTION"/>
                        <CardContent>
                            <Typography variant='body1' component='h6'>
                                Stake POTION to earn POTION. You can stake as many times as you want. You can unstake after 7 days.                            
                            </Typography>
                            <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                                <Typography variant='body1' component='h6'>
                                    APR
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {apyT? apyT.toString(): "0"}%
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" justifyContent="space-between" className={ classes.aprBoostColour }>
                                <Typography variant='body1' component='h6'>
                                    APR Boost
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    +{aprBoost? aprBoost: "0"}%
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" justifyContent="space-between">
                                <Typography variant='body1' component='h6'>
                                    My Stake
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {userTokenStaked? parseFloat(utils.formatEther(userTokenStaked)).toFixed() : "0"}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" justifyContent="space-between">
                                <Typography variant='body1' component='h6'>
                                    Total Stake
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {totalTokenStaked ? parseFloat(utils.formatEther(totalTokenStaked)).toFixed() : "0"}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display={userTimeToUnstakeFormatted ? "flex" : "none"} justifyContent="space-between">
                                <Typography variant='body1' component='h6'>
                                    Time To Unstake
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {userTimeToUnstakeFormatted}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Button variant="contained" color="primary" ml={2} onClick={() => handleUnStakeToken()}>
                                    UNSTAKE
                                </Button>
                            </Box>
                            <Box alignItems="center" display="flex" mt={4} justifyContent="space-between">
                                <Box flexDirection="column" display="flex">
                                    <Typography variant='body1' component='h6'>
                                        POTION EARNED
                                    </Typography>
                                    <Typography variant='body1' component='h6'>
                                        {unclaimedTokenT ? parseFloat(utils.formatEther(unclaimedTokenT)).toFixed(4) : "..."}
                                    </Typography>
                                </Box>
                                <Button variant="contained" color="primary" ml={2} onClick={() => handleClaimToken_T()}>
                                    HARVEST
                                </Button>
                            </Box>
                            <Box mt={2}>
                                <Box mb={1}>
                                    <Typography variant='body1' component='h6'>
                                        There is no minimum to stake
                                    </Typography>
                                </Box>
                                <Box alignItems="center" display="flex" justifyContent="space-between">
                                    <TextField
                                        label="Enter Amount" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setUseTokenSpend(event.target.value)}
                                        value = {useTokenSpend ? useTokenSpend : ""}
                                    />
                                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                                        <Button onClick={() => handleMaxToken()}>MAX</Button>
                                        <Button onClick={() => handleStakeToken()}>STAKE</Button>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                            <Box mt={4}>
                            <Typography variant='body1' component='h6'>
                                POTION Balance: {tokenBalance ? parseFloat(utils.formatEther(tokenBalance)).toFixed(2) : "0.00"} POTION
                            </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3}>
                <Card>
                    <CardHeader title="SELL POTION"/>
                        <CardContent>
                            <Typography variant='body1' component='h6'>
                                Sell earned tokens. Only 50000 tokens can be sold per day to earn MIM.                            
                            </Typography>
                            <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                                <Typography variant='body1' component='h6'>
                                    SOLD TODAY
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {tokenSoldToday ? parseFloat(utils.formatEther(tokenSoldToday)).toFixed(): "..."}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" justifyContent="space-between">
                                <Typography variant='body1' component='h6'>
                                    AVAILABLE TODAY
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {tokenAvailableToSell ? parseFloat(utils.formatEther(tokenAvailableToSell)).toFixed() : "..."}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" justifyContent="space-between">
                                <Typography variant='body1' component='h6'>
                                    RESET IN
                                </Typography>
                                <Typography variant='body1' component='h6'>
                                    {timeToNextDayFormatted ? timeToNextDayFormatted : "..."}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" mt={2} justifyContent="center">
                                <Box flexDirection="column" display="flex" alignItems="center">
                                    <Typography variant='body1' component='h6'>
                                        POTION PRICE
                                    </Typography>
                                    <Typography variant='body1' component='h6'>
                                        {tokenPrice ? parseFloat(utils.formatEther(tokenPrice)).toFixed(4) : "..."}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Box mb={1}>
                                    <Typography variant='body1' component='h6'>
                                        You will get {tokenPrice && tokenToBeSold ? (parseFloat(utils.formatEther(tokenPrice)) * parseFloat(tokenToBeSold)).toFixed(2) : "0.00" } MIM
                                    </Typography>
                                </Box>
                                <Box alignItems="center" display="flex" justifyContent="space-between">
                                    <TextField
                                        title="Approve MIM spend"
                                        label="Enter Amount" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setTokenToBeSold(event.target.value)}
                                        value = {tokenToBeSold ? tokenToBeSold : ""}
                                    />
                                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                                        <Button onClick={() => handleMaxTokenToBeSold()}>MAX</Button>
                                        <Button onClick={() => handleSellToken()}>SELL</Button>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                            <Box mt={4}>
                            <Typography variant='body1' component='h6'>
                                POTION Balance: {tokenBalance ? parseFloat(utils.formatEther(tokenBalance)).toFixed(2) : "0.00"} POTION
                            </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Stake;