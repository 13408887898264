import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "#2a314a",
          padding: theme.spacing(4, 8, 6),
          color: "#FBF5B7"
      },
      btnColorCard: {
        backgroundColor: "#FBF5B7",
        color:"#000000",
        "&:hover": {
          backgroundColor: "#FBF5B7",
          color:"#000000",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#FBF5B7",
            color:"#000000",
          }
        }
      },
    }
});

function EnterMiner() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start">
                <Typography variant='h2'><strong>ENTER THE MIM STAR MINER ⭐ : </strong> </Typography>
                <br/>
                <Box width={"45%"} display="inline-flex" mt={2}>
                    <Button target="_blank" href="/miner" className={classes.btnColorCard} fullWidth size='large'><strong>ENTER MINER</strong> </Button>
                </Box>
            </Grid>
        </div>    
    );
  }
  
export default EnterMiner;