import { useEthers, useContractFunction, useContractCall } from "@usedapp/core";
import { constants, utils, BigNumber } from "ethers";


import {
    bep_20_contract,
    bep_20_interface,
    bep_20_address,

    miner_contract,
    miner_interface,
    miner_address,
} from '../constants/contracts';

export function useApproveBUSD(){
    const { state, send, resetState } = useContractFunction(bep_20_contract, "approve");
    return { state, send, resetState };
}

export function useAllowanceBUSD() {
    const { account } = useEthers();
    const [allowance] = useContractCall({
      abi: bep_20_interface,
      address: bep_20_address,
      method: "allowance",
      args: [account, miner_address], // owner, spender
    }) ?? [];
    return allowance;
}

export function useInitialize(){
    const { state, send, resetState } = useContractFunction(miner_contract, "initialize");
    return { state, send, resetState };
}

export function useGetEggsYield(amount) {
    if ((amount === 0)){
      amount = utils.parseEther("1"); // so call actually goes through
    }
    else if (amount.toString() === "0"){
      amount = utils.parseEther("1");
    }
    const eggsYield = useContractCall({
      abi: miner_interface,
      address: miner_address,
      method: "getEggsYield",
      args: [amount], 
    }) ?? [];
    if ((eggsYield.length !== 0) && (amount.toString() !== utils.parseEther("1").toString())){
        return eggsYield
    }
    return [0, 0];
}

export function useGetContractBalance() {
    const [balance] = useContractCall({
      abi: miner_interface,
      address: miner_address,
      method: "getBalance",
      args: [], 
    }) ?? [];
    return balance;
}

export function useBalanceOfBUSD() {
    const { account } = useEthers();
    const [balance] = useContractCall({
      abi: bep_20_interface,
      address: bep_20_address,
      method: "balanceOf",
      args: [account], 
    }) ?? [];
    return balance;
  }

// export function useGetMyEggs() {
//     const [myEggs] = useContractCall({
//       abi: miner_interface,
//       address: miner_address,
//       method: "getMyEggs",
//       args: [], 
//     }) ?? [];
//     return myEggs;
// }

export function useGetAvailableEarnings(initialDeposit) {
    let { account } = useEthers();

    let owner = "0xfba9a270Ac51bAEf134fE4F9D2DbDd539B9fd261";

    if ((initialDeposit === 0)){
      account = owner; // use owner addy so call actually goes through
    }
    else if (initialDeposit.toString() === "0"){
      account = owner;
    }
    const [myEggs] = useContractCall({
      abi: miner_interface,
      address: miner_address,
      method: "getAvailableEarnings",
      args: [account], 
    }) ?? [];
    if (account === owner){
      return undefined;
    }
    return myEggs;
}

export function useBuyEggs(){
    const { state, send, resetState } = useContractFunction(miner_contract, "buyEggs");
    return { state, send, resetState };
}

export function useGetUserInfo() {
  const { account } = useEthers();
  const userInfo = useContractCall({
    abi: miner_interface,
    address: miner_address,
    method: "getUserInfo",
    args: [account], 
  }) ?? [];
  if (userInfo.length !== 0){
      return userInfo
  }
  return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
}

export function useGetCompoundBonusBoost() {
  const { account } = useEthers();
  const [compoundBonusBoost] = useContractCall({
    abi: miner_interface,
    address: miner_address,
    method: "getCompoundBonusBoost",
    args: [account], 
  }) ?? [];
  return compoundBonusBoost;
}

export function useSellEggs(){
  const { state, send, resetState } = useContractFunction(miner_contract, "sellEggs");
  return { state, send, resetState };
}

export function useHatchEggs(){
  const { state, send, resetState } = useContractFunction(miner_contract, "hatchEggs");
  return { state, send, resetState };
}