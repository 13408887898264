import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#91B2E2",          
        paddingTop: 8,
        paddingBottom: "5%"
      },
      mintTitle: {
          padding: theme.spacing(4, 8, 6)
      },
      card: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
      },
      wizOwned: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: "20%",
      },
      head: {
          textAlign: "center",
      },
      cardActionArea: {
          width: "100%",
          paddingBottom: 0
      }
    }
});


function Information() {
    const classes = useStyles();


    return (
        <div className={ classes.container } id="info">
            <Grid container direction="column" justifyContent="center"  alignItems="center" className={classes.mintTitle}>
                <Grid item>
                    <Grid item>
                        <Card className={ classes.card }>
                            <CardHeader
                                className={ classes.head }
                                title="How it works"
                            />
                            <CardContent>
                            <Typography variant='body1' component='h6'>
                                
                                The value of POTION will rise as more users invest MIM. <br/><br/>

                                Process <br/>
                                1.1. Enter MIM into the MIM MACHINE and earn daily staking rewards in POTION tokens (2% per day). You can claim your POTION rewards at anytime. You cannot unstake your MIM. <br/>
                                1.2. You will earn POTION as a percentage of MIM invested irrespective of the POTION Price. <br/>
                                1.3. i.e. If you invest 100 MIM you will receive 2 POTION per day.<br/><br/>

                                2. You have two options with your POTION, you can sell them for MIM, or you can stake them to earn more POTION (4% per day). POTION staking is locked for 7 days. <br/>
                                2.1 You can mint a wizard to boost your APR. Your APR Boost is calculated based on the <strong>HIGHEST LEVEL</strong> wizard you own. You cannot compound your APR Boosts.<br/>
                                2.2.a) i.e if you own a Gold and Silver Wizard, the highest level wizard you own is Gold, so you will recieve the Gold APR Boost (+547.5%).<br/>
                                2.2.b) Another example. If you own 20 Silver Wizards and 10 Bronze Wizards, the highest level wizard you own is Silver, so you will recieve the Silver APR Boost (+365%).<br/>
                                2.3 Note how there is no circumstance in which your APR Boost compounds.<br/><br/>

                                3. Terminology <br/>
                                3.1. Total Supply : The maximum amount of POTION that can exist <br/>
                                3.2. Circulating Supply : The amount of POTION that are currently in wallets. <br/>
                                3.3. Available Supply : (Total Supply - Circulating Supply) <br/>
                                3.4. POTION Price : (Total MIM Balance / Available Supply) <br/>

                                4. Mint POTION - As you claim POTION from minting, it is removed from the Available Supply and added to the Circulating Supply <br/>

                                5. Sell POTION - As you sell POTION, it is removed from the Circulating Supply and added to the Available Supply.<br/><br/> 

                                DYOR! There is always a risk of the uncertain with smart contracts. Only use funds you can afford to lose. <br/>
                            </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Information;