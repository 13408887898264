import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ConnectWalletBtn from '../components/ConnectWalletBtn';

import Logo from "../images/mini_gold_wiz.png";

import Pdf from '../pdf/MIM_Machine_Whitepaper.pdf';

const btnColorContrastText = "#3494E6"
const btnColorGradient = "linear-gradient(to bottom right, #EC6EAD, #3494E6)"
const btnColorGradientHover = "linear-gradient(to bottom right, #de62a0, #2b88d9)"

const useStyles = makeStyles((theme) => {
    return {
      appBar: {
        backgroundImage: "linear-gradient(to bottom right, #4A00E0, #8E2DE2)",
        paddingBottom: theme.spacing(4)
      },
      logo: {
        borderRadius: "50%",
        height: "auto",
        width: "70%",
        backgroundImage: "linear-gradient(to bottom right, #c471ed, #12c2e9)",
      },
      buttonMargin: {
        marginTop: theme.spacing(3)
      },
      btnColor: {
        color: theme.palette.getContrastText(btnColorContrastText),
        backgroundImage: btnColorGradient,
        "&:hover": {
          backgroundImage: btnColorGradientHover,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundImage: btnColorGradient
          }
        }
      },
    }
});


function Navbar(props) {
    const {setIsConnected} = props;
    const classes = useStyles();

    return (
        <div >
            <AppBar className={ classes.appBar } position="relative">
                <Toolbar>
                <Grid container direction="row" alignContent='center' justifyContent='center'>
                  <Grid container item lg={3} md={3} sm={6}>
                    <Box 
                          className={ classes.logo }
                          component="img"
                          alt="MIM Machine Logo"
                          src={Logo}
                          mt={5}
                      />
                      <Box>
                    </Box>
                  </Grid>
                  <Grid container item direction='row' lg={6} md={6} xs={6} alignContent="center" className={ classes.buttonMargin }>
                    <Box mr={3} mt={2}>
                      <Button target="_blank" href="https://discord.gg/3NjFxa4Ctt" className={ classes.btnColor } variant="contained"  size='large'>
                          DISCORD
                      </Button>
                    </Box>
                    <Box mr={3} mt={2}>
                      <Button className={ classes.btnColor } target="_blank" href={Pdf} variant="contained"  size='large'>
                          WhitePaper
                      </Button>
                    </Box>
                    <Box mr={3} mt={2}>
                      <Button target="_blank" href="https://coinsult.net/projects/mim" className={ classes.btnColor } variant="contained"  size='large'>
                          audit
                      </Button>
                    </Box>
                    <Box mr={3} mt={2}>
                      <ConnectWalletBtn classes={classes} setIsConnected={setIsConnected}/>
                    </Box>
                  </Grid>
                </Grid>
                </Toolbar>
            </AppBar>
        </div>    
    );
  }
  
export default Navbar;