import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";

import howToPlay from "../../images/how_to_play.png";


const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#2a314a",
        padding: theme.spacing(4, 8, 6),
        paddingTop: "0",
        color: "#FBF5B7"
      },
      buttonMargin: {
        marginTop: theme.spacing(8)
      },
      btnColor: {
        backgroundColor: "#36478a",
        color: "#11d0ed",
        "&:hover": {
          backgroundColor: "#2a314a",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#2a314a",
          }
        }
      },
      card: {
        backgroundColor: "#2a314a",
        padding: theme.spacing(4, 6, 4),
        paddingTop: "0",
        color: "#11d0ed",
        borderColor: "#FBF5B7",
        width: "30rem"
      },
      notchedOutline: {
        borderColor: "#11d0ed !important",
      },
      textFieldColor: {
        color: "#11d0ed !important",
        fontWeight: "bold",
        fontSize:"1.4rem",
        textAlign:"right"
      },
      btnColorCard: {
        backgroundColor: "#36478a",
        color: "#11d0ed",
        "&:hover": {
          backgroundColor: "#FBF5B7",
          color:"#000000",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#FBF5B7",
            color:"#000000",
          }
        }
      },
      divider: {
        marginTop: "8%",
        width: "100%",
        backgroundColor: "#ffffff"
      },
      dividerLessMargin: {
        marginTop: "2%",
        width: "100%",
        backgroundColor: "#ffffff"
      },
      gridMargin: {
          marginTop: "4%"
      },
      bronzeColor: {
        color: "#E9C38A"
      },
      silverColor: {
        color: "#E1E0E4"
      },
      goldColor: {
          color: "#FBF5B7"
      },
      logo: {
        height: "70%",
        width: "70%",
      },
    }
});


function Play() {
    const classes = useStyles();
    
    return (
        <div className={ classes.container }>
            <Typography variant="h3" align="center" gutterBottom>
                <strong>WELCOME TO THE LOOP</strong> 
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
                <strong>MINER OR MACHINE? STARS OR POTION? PICK YOUR STRATEGY:</strong> 
            </Typography>
            <Grid container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
                    <Box 
                        className={ classes.logo }
                        component="img"
                        alt="MIM Machine Logo"
                        src={howToPlay}
                        display="flex"
                    />
              </Grid>
            </Grid>
            <Grid className={classes.gridMargin} container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
                <Box width={"45%"} display="inline-flex">
                    <Button target="_blank" href={howToPlay} className={classes.btnColorCard} fullWidth size='large'><strong>VIEW FULL IMAGE HERE</strong> </Button>
                </Box>
              </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Play;