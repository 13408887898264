import { useEthers, useContractFunction, useContractCall } from "@usedapp/core";

import {
    bep_20_contract,
    bep_20_interface,
    bep_20_address,

    wizard_of_mim_contract,
    wizard_of_mim_interface,
    wizard_of_mim_address,
} from '../constants/contracts';

export function useApproveBUSD(){
    const { state, send, resetState } = useContractFunction(bep_20_contract, "approve");
    return { state, send, resetState };
}

export function useAllowanceBUSD() {
    const { account } = useEthers();
    const [allowance] = useContractCall({
      abi: bep_20_interface,
      address: bep_20_address,
      method: "allowance",
      args: [account, wizard_of_mim_address], // owner, spender
    }) ?? [];
    return allowance;
}

export function useMintWizard(){
    const { state, send, resetState } = useContractFunction(wizard_of_mim_contract, "mintWizard");
    return { state, send, resetState };
}

export function useGetTotalWizMint() {
    const getTotalWizMint = useContractCall({
      abi: wizard_of_mim_interface,
      address: wizard_of_mim_address,
      method: "getTotalWizMint",
      args: [], 
    }) ?? [];
    if (getTotalWizMint.length !== 0){
        return getTotalWizMint
    }
    return [0, 0, 0];
}

export function useGetMaxWizCount() {
    const [getMaxWizCount] = useContractCall({
      abi: wizard_of_mim_interface,
      address: wizard_of_mim_address,
      method: "getMaxWizCount",
      args: [], 
    }) ?? [];
    if (getMaxWizCount){
        return getMaxWizCount;
    }
    return 0;
}
