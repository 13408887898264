import { useEthers, useContractFunction, useContractCall } from "@usedapp/core"

import { mim_machine_contract, mim_machine_interface, mim_machine_address } from "../constants/contracts";
import { Contract } from "@ethersproject/contracts";



export function useGetTokenPrice() {
    const [tokenPrice] = useContractCall({
      abi: mim_machine_interface,
      address: mim_machine_address,
      method: "getTokenPrice",
      args: [],
    }) ?? [];
    return tokenPrice;
}

export function useTotalUsers() {
    const [totalUsers] = useContractCall({
      abi: mim_machine_interface,
      address: mim_machine_address,
      method: "totalUsers",
      args: [],
    }) ?? [];
    return totalUsers;
}

// circulating supply
export function useTotalSupply() {
    const [totalSupply] = useContractCall({
      abi: mim_machine_interface,
      address: mim_machine_address,
      method: "totalSupply",
      args: [],
    }) ?? [];
    return totalSupply;
}

export function useLimitSupply() {
    const [limitSupply] = useContractCall({
      abi: mim_machine_interface,
      address: mim_machine_address,
      method: "limitSupply",
      args: [],
    }) ?? [];
    return limitSupply;
}

export function useAvailableSupply() {
    const [availableSupply] = useContractCall({
      abi: mim_machine_interface,
      address: mim_machine_address,
      method: "availableSupply",
      args: [],
    }) ?? [];
    return availableSupply;
}

