import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";

// import WizardOfMIM from '../abi/local/contracts/WizardOfMIM.json';
// import MIMMachine from '../abi/local/contracts/MIMMachine.json';
// import BEP20Token from '../abi/local/contracts/BEP20Token.json';
// import Miner from '../abi/local/contracts/MIMStar.json';


// export const wizard_of_mim_abi = WizardOfMIM.abi
// export const wizard_of_mim_address = "0x4D1B781ce59B8C184F63B99D39d6719A522f46B5"
// export const wizard_of_mim_interface = new utils.Interface(wizard_of_mim_abi)
// export const wizard_of_mim_contract = new Contract(wizard_of_mim_address, wizard_of_mim_interface)


// export const mim_machine_abi = MIMMachine.abi
// export const mim_machine_address = "0xf9C8Cf55f2E520B08d869df7bc76aa3d3ddDF913"
// export const mim_machine_interface = new utils.Interface(mim_machine_abi)
// export const mim_machine_contract = new Contract(mim_machine_address, mim_machine_interface)

// export const bep_20_abi = BEP20Token.abi
// export const bep_20_address = "0xD22363efee93190f82b52FCD62B7Dbcb920eF658"
// export const bep_20_interface = new utils.Interface(bep_20_abi)
// export const bep_20_contract = new Contract(bep_20_address, bep_20_interface)

// export const miner_abi = Miner.abi
// export const miner_address = "0xD22363efee93190f82b52FCD62B7Dbcb920eF658"
// export const miner_interface = new utils.Interface(miner_abi)
// export const miner_contract = new Contract(miner_address, miner_interface)


// import WizardOfMIM from '../abi/fuji/WizardOfMIM.json';
// import MIMMachine from '../abi/fuji/MIMMachine.json';
// import BEP20Token from '../abi/fuji/MIM.json';
// import Miner from '../abi/fuji/MIMStar.json';


// export const wizard_of_mim_abi = WizardOfMIM.abi
// export const wizard_of_mim_address = "0xf15321010578FC8f9C2Dd6A8840be836e4Bc810c"
// export const wizard_of_mim_interface = new utils.Interface(wizard_of_mim_abi)
// export const wizard_of_mim_contract = new Contract(wizard_of_mim_address, wizard_of_mim_interface)

// export const mim_machine_abi = MIMMachine.abi
// export const mim_machine_address = "0x552760284614D4Aec36819aADcE9DD534CF7f606"
// export const mim_machine_interface = new utils.Interface(mim_machine_abi)
// export const mim_machine_contract = new Contract(mim_machine_address, mim_machine_interface)

// export const bep_20_abi = BEP20Token.abi
// export const bep_20_address = "0x746ddfcF2F9f3098789858EEDf6642E9D735dDf8"
// export const bep_20_interface = new utils.Interface(bep_20_abi)
// export const bep_20_contract = new Contract(bep_20_address, bep_20_interface)

// export const miner_abi = Miner.abi
// export const miner_address = "0xdE49072264eB4dd8D93C5690117E9feC68656b39" // FINAL VERSION (GOING TO PROD)

// // export const miner_address = "0x693Aa4BA13447A51a4d098a983Da7df5744b7B60" // FINAL VERSION (GOING TO PROD), NOPE!

// // export const miner_address = "0xe91F95A11790E4D680391a9D17cbE2578c40dCB5" // INIT 10 MAX COMPOUND TEST
// // export const miner_address = "0x340e70F2E1Fd009a2880F6e7cF334562c04041F7"
// // export const miner_address = "0x7b6Ece18b2902e1A65A840b812a79a096f289cb6"
// export const miner_interface = new utils.Interface(miner_abi)
// export const miner_contract = new Contract(miner_address, miner_interface)


import WizardOfMIM from '../abi/avax/WizardOfMIM.json';
import MIMMachine from '../abi/avax/MIMMachine.json';
import BEP20Token from '../abi/avax/MIM.json';
import Miner from '../abi/avax/MIMStar.json';


export const wizard_of_mim_abi = WizardOfMIM.abi
export const wizard_of_mim_address = "0xEc0D3ff96d290100DecB789B3f1cDd4f2A47E7c5"
export const wizard_of_mim_interface = new utils.Interface(wizard_of_mim_abi)
export const wizard_of_mim_contract = new Contract(wizard_of_mim_address, wizard_of_mim_interface)

export const mim_machine_abi = MIMMachine.abi
export const mim_machine_address = "0x2A7452dD781D7084C913D1E29c60891f70fA66c9"
export const mim_machine_interface = new utils.Interface(mim_machine_abi)
export const mim_machine_contract = new Contract(mim_machine_address, mim_machine_interface)

export const bep_20_abi = BEP20Token.abi
export const bep_20_address = "0x130966628846BFd36ff31a822705796e8cb8C18D" // mim
export const bep_20_interface = new utils.Interface(bep_20_abi)
export const bep_20_contract = new Contract(bep_20_address, bep_20_interface)

export const miner_abi = Miner.abi
export const miner_address = "0xe159072Ee23DA6c185cD731E4F776A8F11Cf16B8" // FINAL VERSION (GOING TO PROD)
export const miner_interface = new utils.Interface(miner_abi)
export const miner_contract = new Contract(miner_address, miner_interface)