import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";


import { useSearchParams, createSearchParams } from "react-router-dom"

import Snackbars from '../../components/Snackbars';


import { constants, utils, BigNumber } from "ethers";

import { useApproveBUSD, useAllowanceBUSD, useInitialize, useGetEggsYield, useGetContractBalance, useBalanceOfBUSD, useGetAvailableEarnings, useBuyEggs, useGetUserInfo, useGetCompoundBonusBoost, useSellEggs, useHatchEggs } from "../../hooks/minerHooks";

import { miner_address } from "../../constants/contracts";

import { useEthers } from '@usedapp/core';
import { type } from '@testing-library/user-event/dist/type';

const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#2a314a",
        padding: theme.spacing(4, 8, 6),
        paddingTop: "0",
        color: "#FBF5B7"
      },
      buttonMargin: {
        marginTop: theme.spacing(8)
      },
      btnColor: {
        backgroundColor: "#36478a",
        color: "#11d0ed",
        "&:hover": {
          backgroundColor: "#2a314a",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#2a314a",
          }
        }
      },
      card: {
        backgroundColor: "#2a314a",
        padding: theme.spacing(4, 6, 4),
        paddingTop: "0",
        color: "#11d0ed",
        borderColor: "#FBF5B7",
        width: "30rem"
      },
      notchedOutline: {
        borderColor: "#11d0ed !important",
      },
      textFieldColor: {
        color: "#11d0ed !important",
        fontWeight: "bold",
        fontSize:"1.4rem",
        textAlign:"right"
      },
      textFieldColorRef: {
        color: "#11d0ed !important",
        fontWeight: "bold",
        fontSize:"1rem",
      },
      btnColorCard: {
        backgroundColor: "#36478a",
        color: "#11d0ed",
        "&:hover": {
          backgroundColor: "#FBF5B7",
          color:"#000000",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#FBF5B7",
            color:"#000000",
          }
        },
        "&:disabled": {
            color: "#ffffff",
            opacity: 0.5
        }
      },
      divider: {
        marginTop: "8%",
        width: "100%",
        backgroundColor: "#ffffff"
      },
      dividerLessMargin: {
        marginTop: "2%",
        width: "100%",
        backgroundColor: "#ffffff"
      },
      gridMargin: {
          marginTop: "4%"
      },
      bronzeColor: {
        color: "#E9C38A"
      },
      silverColor: {
        color: "#E1E0E4"
      },
      goldColor: {
          color: "#FBF5B7"
      },
      potColor: {
        color: "#EC6EAD"
    },
    }
});

// let y;
// var cutoffStep = 48 * 60 * 60;
// function setCutoffTimer(lastHatch, setClaimTimerState) {
//     var time = new Date().getTime();
//     var cutoff = (+lastHatch + +cutoffStep) - (+time/1000);
//     var countDownDate = new Date(+time + (+cutoff * 1000)).getTime();

//     clearInterval(y)
//     y = setInterval(function() {
//         var currentTime = new Date().getTime();
//         // Find the distance between now and the count down date
//         var distance = countDownDate - currentTime;

//         // Time calculations for days, hours, minutes and seconds
//         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
//         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + days*24);
//         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         var seconds = Math.floor((distance % (1000 * 60)) / 1000);

//         if (hours < 10) { hours = '0' + hours; }
//         if (minutes < 10) { minutes = '0' + minutes; }
//         if (seconds < 10) { seconds = '0' + seconds; }
        
//         setClaimTimerState(`${hours}h:${minutes}m:${seconds}s`);

//         // If the count down is finished, write some text
//         if (distance < 0) {
//             // clearInterval(y);
//             setClaimTimerState("0:00:00");
//         }
//     }, 1000, 1);
// }

// var x;
// var compoundStep = 12 * 60 * 60;
// function setCompoundTimer(lastHatch, setCompoundTimerState, setDisableCompoundTimer) {
//     var now = new Date().getTime();
//     var diff = (+lastHatch + +compoundStep) - (now / 1000);
//     var countDownDate = new Date(+now + (+diff * 1000)).getTime();

//     clearInterval(x)
//     x = setInterval(function () {
//         var currTime = new Date().getTime();
//         // Find the distance between now and the count down date
//         var distance = countDownDate - currTime;


//         // Time calculations for days, hours, minutes and seconds
//         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
//         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + days*24);
//         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         var seconds = Math.floor((distance % (1000 * 60)) / 1000);


//         if (hours < 10) { hours = '0' + hours; }
//         if (minutes < 10) { minutes = '0' + minutes; }
//         if (seconds < 10) { seconds = '0' + seconds; }

        

//         // If the count down is finished, write some text
//         if (distance < 0) {
//             setDisableCompoundTimer(false);
//             setCompoundTimerState("00:00:00");
//         }
//         else{
//             setCompoundTimerState(`${hours}h:${minutes}m:${seconds}s`);
//         }
//     }, 1000, 1);
// }

// var z;
// var withdrawCooldown = 4 * 60 * 60;
// function setCooldownTimer(lastHatch, setWithdrawTimerState, setDisableWithdrawTimer) {
//     var now = new Date().getTime() / 1000;
//     var coolDownDiff = (+lastHatch + +withdrawCooldown) - now;

//     var time = new Date().getTime();
//     var endDate = new Date(+time + (+coolDownDiff * 1000)).getTime();

//     clearInterval(z)
//     z = setInterval(function() {
//         var currTime = new Date().getTime();

//         // Find the distance between now and the count down date
//         var distance = endDate - currTime;
//         // Time calculations for days, hours, minutes and seconds
//         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
//         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)  + days*24);
//         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         var seconds = Math.floor((distance % (1000 * 60)) / 1000);

//         if (hours < 10) { hours = '0' + hours; }
//         if (minutes < 10) { minutes = '0' + minutes; }
//         if (seconds < 10) { seconds = '0' + seconds; }

//         setWithdrawTimerState(`IN: ${hours}h:${minutes}m:${seconds}s`);
        
//         // If the count down is finished, write some text
//         if (distance < 0) {
//             // clearInterval(z);
//             setDisableWithdrawTimer(false);
//             setWithdrawTimerState("");
//         }
//     }, 1000, 1);
// }

// TIMERS
function starBagFullTimer(countDownDate, setClaimTimerState) {
    var currentTime = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = countDownDate - currentTime;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + days*24);
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (hours < 10) { hours = '0' + hours; }
    if (minutes < 10) { minutes = '0' + minutes; }
    if (seconds < 10) { seconds = '0' + seconds; }
    
    
    // If the count down is finished, write some text
    if (distance < 0) {
        // clearInterval(y);
        setClaimTimerState("0:00:00");
    }
    else{
        setClaimTimerState(`${hours}h:${minutes}m:${seconds}s`);
    }
}

function withdrawTimer(endDate, setWithdrawTimerState, setDisableWithdrawTimer) {
    var currTime = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = endDate - currTime;
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)  + days*24);
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (hours < 10) { hours = '0' + hours; }
    if (minutes < 10) { minutes = '0' + minutes; }
    if (seconds < 10) { seconds = '0' + seconds; }

    
    
    // If the count down is finished, write some text
    if (distance < 0) {
        // clearInterval(z);
        setDisableWithdrawTimer(false);
        setWithdrawTimerState("");
    }
    else{
        setDisableWithdrawTimer(true);
        setWithdrawTimerState(`IN: ${hours}h:${minutes}m:${seconds}s`);
    }
}

function compoundTimer(countDownDate, setCompoundTimerState, setDisableCompoundTimer) {
    var currTime = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = countDownDate - currTime;


    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + days*24);
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);


    if (hours < 10) { hours = '0' + hours; }
    if (minutes < 10) { minutes = '0' + minutes; }
    if (seconds < 10) { seconds = '0' + seconds; }

    
    // If the count down is finished, write some text
    if (distance < 0) {
        setDisableCompoundTimer(false);
        setCompoundTimerState("00:00:00");
    }
    else{
        setDisableCompoundTimer(true);
        setCompoundTimerState(`${hours}h:${minutes}m:${seconds}s`);
    }
}

var z;
var withdrawCooldown = 4 * 60 * 60;
var compoundStep = 12 * 60 * 60;
var cutoffStep = 48 * 60 * 60;
// function setTimers(lastHatch, setClaimTimerState, setWithdrawTimerState, setDisableWithdrawTimer, setCompoundTimerState, setDisableCompoundTimer) {
//     var now = new Date().getTime() / 1000;
//     var time = new Date().getTime();

//     // star bag full in stuff
//     var cutoff = (+lastHatch + +cutoffStep) - (+time/1000);
//     var cutOffCountDownDate = new Date(+time + (+cutoff * 1000)).getTime();

//     // withdraw stuff
//     var coolDownDiff = (+lastHatch + +withdrawCooldown) - now;
//     var withdrawEndDate = new Date(+time + (+coolDownDiff * 1000)).getTime();

//     // compound stuff
//     var diff = (+lastHatch + +compoundStep) - (now / 1000);
//     var compoundCountDownDate = new Date(+now + (+diff * 1000)).getTime();

    
//     clearInterval(z)
//     z = setInterval(function() {
//         starBagFullTimer(cutOffCountDownDate, setClaimTimerState);

//         withdrawTimer(withdrawEndDate, setWithdrawTimerState, setDisableWithdrawTimer);

//         compoundTimer(compoundCountDownDate, setCompoundTimerState, setDisableCompoundTimer);
//     });
// }

function Mine() {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    // referrer
    const [searchParams, setSearchParams] = useSearchParams({});
    const myReferrer = searchParams.get("ref"); 
    const [ref, setRef] = useState("");  
    useEffect(() => {
        if(!myReferrer) {
            if (ref === "") {
                setSearchParams({ref: "0x5348bbd91BEb34af12780F07D206d2FA3EB15B63"})
                setRef("0x5348bbd91BEb34af12780F07D206d2FA3EB15B63");
            }
        }
        else{
            if (ref === "") {
                setRef(myReferrer);
            }
        }
      }, [myReferrer]);
   

    // set personal ref link of current user
    const { activateBrowserWallet, account, deactivate } = useEthers();
    const [refLink, setRefLink] = useState("");
    if ((account !== undefined) && (refLink === "")){
        setRefLink(`https://mim-machine.com/miner?ref=${account}`);
    }

    // user info
    let [
        initialDeposit,
        userDeposit,
        miners,
        claimedEggs,
        lastHatch,
        referrer,
        referrals,
        totalWithdrawn,
        referralEggRewards,
        dailyCompoundBonus,
        lastWithdrawTime
    ] = useGetUserInfo();

    // tax
    const COMPOUND_FOR_NO_TAX_WITHDRAWAL = 10;
    const [showTax, setShowTax] = useState(undefined);
    if (showTax === undefined) {
        if (dailyCompoundBonus !== 0) {
            if (parseInt(dailyCompoundBonus) < COMPOUND_FOR_NO_TAX_WITHDRAWAL) {
                setShowTax("");
            }
            else{
                setShowTax("None");
            }
        }
    }

    // timers
    const [compoundTimerState, setCompoundTimerState] = useState("");
    const [disableCompoundTimer, setDisableCompoundTimer] = useState(true);
    
    const [claimTimerState, setClaimTimerState] = useState("");
    
    const [withdrawTimerState, setWithdrawTimerState] = useState("");
    const [disableWithdrawTimer, setDisableWithdrawTimer] = useState(true);
    
    useEffect(() => {
        if (lastHatch !== 0){
            var now = new Date().getTime() / 1000;
            var time = new Date().getTime();

            // star bag full in stuff
            var cutoff = (+lastHatch + +cutoffStep) - (+time/1000);
            var cutOffCountDownDate = new Date(+time + (+cutoff * 1000)).getTime();

            // withdraw stuff
            var coolDownDiff = (+lastHatch + +withdrawCooldown) - now;
            var withdrawEndDate = new Date(+time + (+coolDownDiff * 1000)).getTime();

            // compound stuff
            var diff = (+lastHatch + +compoundStep) - (now / 1000);
            var compoundCountDownDate = new Date(+now + (+diff * 1000)).getTime();

            
            clearInterval(z)
            z = setInterval(function() {
                starBagFullTimer(cutOffCountDownDate, setClaimTimerState);

                withdrawTimer(withdrawEndDate, setWithdrawTimerState, setDisableWithdrawTimer);

                compoundTimer(compoundCountDownDate, setCompoundTimerState, setDisableCompoundTimer);
            }, 1000, 1);
        }

        return () => {
            clearInterval(z);
          };
      }, [lastHatch]);

    
    let [, earnings] = useGetEggsYield(userDeposit);
    if (earnings !== 0) {
        earnings = parseFloat(utils.formatEther(earnings).slice(0, -2)).toFixed(2);
    }
    

    const contractBalance = useGetContractBalance();
    const busdBalance = useBalanceOfBUSD();
    const myEggs = useGetAvailableEarnings(initialDeposit);


    const compoundBoost = useGetCompoundBonusBoost();
    
    const [approveMIMSpend, setApproveMIMSpend] = useState(undefined);
    const [useMIMSpend, setUseMIMSpend] = useState(undefined);

    let userAllowance = useAllowanceBUSD();
    if (userAllowance && approveMIMSpend === undefined) {
        userAllowance = utils.formatEther(userAllowance).slice(0, -2);
        setApproveMIMSpend(userAllowance);
    }

    // approve busd
    const { state: approveBUSDState, send: approveBUSD, events: approveBUSDEvents, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(){   
        console.log(approveMIMSpend);     
        approveBUSD(miner_address, utils.parseEther(approveMIMSpend)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            let allowance = approveBUSDEvents[0].args.value;
            allowance = utils.formatEther(allowance).slice(0, -2);
            setApproveMIMSpend(allowance);
            resetApproveBUSD();
        }
    }, [approveBUSDEvents]);

    // buyEggs
    const { state: buyEggsState, send: buyEggs, events: buyEggsEvents, resetState: resetBuyEggs } = useBuyEggs();
    function handleBuyEggs(){      
        if(parseInt(useMIMSpend) <= parseInt(approveMIMSpend)) {
            console.log("here", ref);
            buyEggs(ref, utils.parseEther(useMIMSpend)).catch(err => {
                setOpen(true);
                setSeverity("warning");
            });
        }
        else {
            setOpen(true);
            setSeverity("warning")
        }
    }

    useEffect(() => {
        if (buyEggsState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (buyEggsState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (buyEggsState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [buyEggsState]);

    // sellEggs
    const { state: sellEggsState, send: sellEggs, events: sellEggsEvents, resetState: resetSellEggs } = useSellEggs();
    function handleSellEggs(){      
        sellEggs().catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (sellEggsState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (sellEggsState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (sellEggsState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [sellEggsState]);

    // hatchEggs
    const { state: hatchEggsState, send: hatchEggs, events: hatchEggsEvents, resetState: resetHatchEggs } = useHatchEggs();
    function handleHatchEggs(){      
        hatchEggs(true).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (hatchEggsState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (hatchEggsState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (hatchEggsState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [hatchEggsState]);
    
    return (
        <div className={ classes.container }>
            <Snackbars open={open} setOpen={setOpen} severity={severity}/>
            <Typography variant="h5" align="center" gutterBottom>
                <strong>MINE STARS. EARN STABLECOINS (MIM). PLAY YOUR STRATEGY.</strong> 
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
                <strong>LIVE NOW ON AVAX.</strong> 
            </Typography>

            <Grid container direction="row" alignItems="center" >
                <Grid container item justifyContent="center">
                    <Box width={"45%"} mb={3} mt={2}>
                        <Button target="_blank" href="/" className={classes.btnColorCard} fullWidth size='large'><strong>ENTER MIM MACHINE</strong> </Button>
                    </Box>
                </Grid>
            </Grid>
            
            <Grid container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
              <Card className={classes.card} variant="outlined">
                    <CardContent>
                        <Grid container spacing={1} direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                <strong>CONTRACT</strong> 
                            </Typography>
                            
                            <Typography variant='h6' component='h6'>
                            {contractBalance? parseFloat(utils.formatEther(contractBalance)).toFixed() : "0"} MIM
                            </Typography>
                        </Grid>
                        
                        <Box mt={2}></Box>

                        <Grid container spacing={1} direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                <strong>WALLET</strong> 
                            </Typography>
                            <Typography variant='h6' component='h6'>
                                {busdBalance ? parseFloat(utils.formatEther(busdBalance)).toFixed(2) : "0.00"} MIM
                            </Typography>
                        </Grid>

                        <Box mt={2}></Box>

                        <Grid container spacing={1} direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                <strong>STARS</strong> 
                            </Typography>
                            <Typography variant='h6' component='h6'>
                            {myEggs? parseFloat(utils.formatEther(myEggs)).toFixed() : "0"} STARS
                            </Typography>
                        </Grid>
                        
                        <Box mt={2}></Box>

                        <Grid container justifyContent="center">
                                
                                <TextField
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            input: classes.textFieldColor
                                        },
                                        endAdornment: <InputAdornment component="div" position="end"><div className={classes.textFieldColor}><strong>MIM</strong></div> </InputAdornment>,
                                    }}
                                    defaultValue="10"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(event) => {
                                        setApproveMIMSpend(event.target.value);
                                        }}
                                />
                
                        </Grid>
                        <Box mt={2}>
                            <Button className={classes.btnColorCard} fullWidth size='large' onClick={() => handleApproveBUSD()}><strong>1. APPROVE</strong> </Button>
                        </Box>

                        <Box mt={2}></Box>

                        <Grid container justifyContent="center">
                                
                                <TextField
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            input: classes.textFieldColor
                                        },
                                        endAdornment: <InputAdornment component="div" position="end"><div className={classes.textFieldColor}><strong>MIM</strong></div> </InputAdornment>,
                                    }}
                                    defaultValue="10"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(event) => {
                                        setUseMIMSpend(event.target.value);
                                        }}
                                />
                
                        </Grid>

                        <Box mt={2}>
                            <Button className={classes.btnColorCard} fullWidth size='large' onClick={() => handleBuyEggs()}><strong>2. HIRE MINERS</strong> </Button>
                        </Box>

                        {/* <Box mt={2}>
                            <Button className={classes.btnColorCard} fullWidth size='large' onClick={() => handleInitialize()}><strong>initialize</strong> </Button>
                        </Box> */}
                        
                        <Divider className={classes.divider}/>
                        
                        <Box mt={3}></Box>
                        
                        {/* <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{0} Miners -> </strong> 
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{0} Stars</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box> */}
                        
                        <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Estimated Daily Yield:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{earnings} MIM</strong> 
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Box mt={2}></Box>

                        <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Miners:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{miners !== 0 ? miners.toString(): 0}</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Total Deposit:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{userDeposit !== 0? parseFloat(utils.formatEther(userDeposit)).toFixed(2): 0} MIM</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Star Bag Full In:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{claimTimerState}</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row">
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Compound Count:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{dailyCompoundBonus !== 0 ? dailyCompoundBonus.toString() : "0"} times</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row">
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Max Compound Bonus:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{compoundBoost ? ((parseInt(compoundBoost.toString())) + (10))*2.5 : 0}%</strong> 
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Box mt={2}></Box>

                        <Grid container spacing={1} direction="column" justifyContent='center'>
                            <Grid item>
                                <Button disabled={disableWithdrawTimer} className={classes.btnColorCard} fullWidth size='large' onClick={() => handleSellEggs()}><strong>COLLECT STARS <Box display={showTax} color={"orange"}>-80% tax</Box> {withdrawTimerState}</strong></Button>
                            </Grid>
                            
                            <Grid item>
                                <Button disabled={disableCompoundTimer} className={classes.btnColorCard} fullWidth size='large' onClick={() => handleHatchEggs()}><strong>RE-HIRE MINERS (+{dailyCompoundBonus*2.5}% Bonus)</strong> </Button>
                            </Grid>

                            <Grid item>
                                <Typography variant='body1' component='h6'>
                                    <strong>Time until next hire bonus is activated: {compoundTimerState}</strong> 
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid className={classes.gridMargin} container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
              <Card className={classes.card} variant="outlined">
                    
                    <CardContent>
                        <Box mb={2}>
                            <Typography variant='h5' component='h6'>
                                <strong>STATISTICS</strong> 
                            </Typography>
                        </Box>

                        <Divider className={classes.dividerLessMargin}/>
                        
                        <Box mt={2}></Box>
                        
                        <Grid container direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                DAILY RETURN
                            </Typography>
                            <Typography variant='h6' component='h6'>
                                UP TO {"8"}%
                            </Typography>
                        </Grid>

                        <Box mt={2}></Box>

                        <Grid container direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                APR
                            </Typography>
                            <Typography variant='h6' component='h6'>
                                UP TO {"2920"}%
                            </Typography>
                        </Grid>

                        <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                            <Typography variant='h5' component='h6'>
                                MAX COMPOUND BONUS
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography variant='h6' component='h6'>
                                Normal: +25% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.bronzeColor} variant='h6' component='h6'>
                                Bronze Wizard Minted: +30% Bonus
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.silverColor} variant='h6' component='h6'>
                                Silver Wizard Minted: +32.5% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.goldColor} variant='h6' component='h6'>
                                Gold Wizard Minted: +35% Bonus 
                            </Typography>
                        </Box>

                        <Box alignItems="center" display="flex" justifyContent="start" mt={4} mb={2} ml={2}>
                            <Typography variant='h6' component='h6'>
                                <strong>OR MINIMUM BALANCE OF:</strong>
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.potColor} variant='h6' component='h6'>
                                500 POTION: +30% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.potColor} variant='h6' component='h6'>
                                1000 POTION: +32.5% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.potColor} variant='h6' component='h6'>
                                1500 POTION: +35% Bonus 
                            </Typography>
                        </Box>
                        
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid className={classes.gridMargin} container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
              <Card className={classes.card} variant="outlined">
                    
                    <CardContent>
                        <Box mb={2}>
                            <Typography variant='h5' component='h6' align='center'>
                                <strong>Referral Link</strong> 
                            </Typography>
                        </Box>
                        
                        <Box mt={2} justifyContent="center" display="flex">
                                
                                <TextField
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            input: classes.textFieldColorRef,
                                        },
                                        endAdornment: (
                                            <Button className={classes.btnColorCard} onClick={() =>  navigator.clipboard.writeText(`${refLink}`)}>Copy</Button>
                                        )
                                    }}
                        
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={refLink}
                                />
                
                        </Box>

                        <Box mt={2}>
                            <Typography variant='body1' component='h6' align='center'>
                                Earn 8% from anyone who uses your referral link
                            </Typography>
                        </Box>

                        <Box mt={2}>
                            <Typography variant='h5' component='h6'>
                                <strong>My Referral Rewards: {referralEggRewards ? parseFloat(utils.formatEther(referralEggRewards)).toFixed(2) : 0} MIM</strong> 
                            </Typography>
                        </Box>
                        
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
                <Card className={classes.card} elevation={0}>
                    <CardContent>
                        <Grid container spacing={1} direction="row">
                            <Grid container item>
                                <Button target="_blank" href="/" className={classes.btnColorCard} fullWidth size='large'><strong>MIM MACHINE</strong> </Button>
                            </Grid>
                            <Grid container item>
                                <Button  target="_blank" href="https://discord.gg/3NjFxa4Ctt" fullWidth className={classes.btnColorCard} size='large'><strong>DISCORD</strong> </Button>
                            </Grid>
                            <Grid container item>
                                <Button  target="_blank" href="https://t.me/MIM_MACHINE_MINER" fullWidth className={classes.btnColorCard} size='large'><strong>TELEGRAM (NEW)</strong> </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
        </div>    
    );
  }

  
export default Mine;