import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Welcome from '../sections/miner/Welcome';
import Mine from '../sections/miner/Mine';
import MintNFT from '../sections/miner/MintNFT';
import Play from '../sections/miner/Play';
import Information from '../sections/miner/Information'
import Disclaimer from '../sections/Disclaimer';


const useStyles = makeStyles((theme) => {
  return {
    root: {
        flexGrow: 1
    },
    disabled: {
      pointerEvents: 'none'
    }
  }
});

function Miner() {
    const classes = useStyles();
    return (
        <div className={ classes.root }>
          <Welcome/>
          <Mine/>
          <Play/>
          <MintNFT/>
          <Information/>
          <Disclaimer/>
        </div>    
    );
  }
  
export default Miner;