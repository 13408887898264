import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Snackbars from '../components/Snackbars';

import bronzeWiz from "../images/bronze_wiz.png";
import silverWiz from "../images/silver_wiz.png";
import goldWiz from "../images/gold_wiz.png";


import { constants, utils, BigNumber } from "ethers";

import { useApproveBUSD, useAllowanceBUSD, useMintWizard, useGetTotalWizMint, useGetMaxWizCount } from "../hooks/mintWizHooks";

import { wizard_of_mim_address } from "../constants/contracts";

import { useEthers } from '@usedapp/core';


const textColorContrastText = "#c471ed"
const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundImage: "linear-gradient(to bottom, #c471ed, #12c2e9)",
          paddingTop: 8,
          paddingBottom: "5%",
          color: theme.palette.getContrastText(textColorContrastText)
      },
      mintTitle: {
          padding: theme.spacing(4, 8, 6)
      },
      card: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundImage: "linear-gradient(to right, #e0eafc, #cfdef3)"
      },
      head: {
          textAlign: "center",
      },
      cardActionArea: {
          width: "100%",
          display: "flex",
          flexDirection: "column" 
      },
      cardSubheader: {
        color: theme.palette.success.main,
      },
      mintBtn: {
        borderRadius: 0
      },
      bronzeBtn: {
        backgroundImage: "linear-gradient(to right, #B08D57, #E9C38A, #FFFFF9, #E9C38A, #B08D57)",
        borderRadius: 0
      },
      silverBtn: {
        backgroundImage: "linear-gradient(to right, #C5C4C8, #E1E0E4, #FEFDFF, #E1E0E4, #C5C4C8)",
        borderRadius: 0
      },
      goldBtn: {
        backgroundImage: "linear-gradient(to right, #BF953F, #FCF6BA, #FBF5B7, #AA771C)",
        borderRadius: 0
      }
    }
});


function MintNFT() {
    const classes = useStyles();

    const { account } = useEthers();

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    
    // user allowance
    const userAllowance = useAllowanceBUSD();
    const [allowance, setAllowance] = useState(undefined);

    if ((userAllowance !== undefined) && (allowance === undefined) ){
        let allowed = utils.formatEther(userAllowance).slice(0, -2); 
        allowed = parseInt(allowed);
        
        setAllowance(allowed); 
    }
    
    // total wizards minted
    let [total_bronze_wiz_minted, total_silver_wiz_minted, total_gold_wiz_minted] = useGetTotalWizMint();
    const maxWizCount= useGetMaxWizCount();

    // approve busd
    const { state: approveBUSDState, send: approveBUSD, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(amount){        
        approveBUSD(wizard_of_mim_address, 
            utils.parseEther(amount)).then(setAllowance(parseInt(amount))).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);

    // mint wizard
    const { state: mintWizardState, send: mintWizard, resetState: resetMintWizard } = useMintWizard();
    function handleMintWizard(level){
        let allowed = parseInt(allowance);
        let URI = "ipfs://QmTcRnxDm4TcwnSi72KFU9QY3Yru6QexmLiVszJVzciJGA/" // metadata CID
        if ((level === 1) && (allowed >= 100)){
            let tokenId = total_bronze_wiz_minted + 1;
            URI = URI + "bronze_wiz" + tokenId.toString() + ".json";
            console.log(URI);
            mintWizard(account, URI, level, utils.parseEther("100")).catch(err => {
                setOpen(true);
                setSeverity("warning");
            });
        }
        else if ((level === 2) && (allowed >= 200)){
            let tokenId = total_silver_wiz_minted + 1;
            URI = URI + "silver_wiz" + tokenId.toString() + ".json";
            console.log(URI);
            mintWizard(account, URI, level, utils.parseEther("200")).catch(err => {
                setOpen(true);
                setSeverity("warning");
            });
        }
        else if ((level === 3) && ((allowed >= 300))){
            let tokenId = total_gold_wiz_minted + 1;
            URI = URI + "gold_wiz" + tokenId.toString() + ".json";
            console.log(URI);
            mintWizard(account, URI, level, utils.parseEther("300")).catch(err => {
                setOpen(true);
                setSeverity("warning");
            });
        }
        else{
            // not enough allowance
            setOpen(true);
            setSeverity("warning"); 
        }
    }

    useEffect(() => {
        if (mintWizardState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (mintWizardState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (mintWizardState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [mintWizardState]);
    
    return (
        <div className={ classes.container }>
            <Snackbars open={open} setOpen={setOpen} severity={severity}/>
            <Box display="flex" flexDirection="column" justifyContent="center"  alignItems="center" className={classes.mintTitle}>
                <Typography component="h2" variant="h3" align="center" gutterBottom>
                <strong>MINT WIZARD</strong> 
                </Typography>
                <Typography variant="h5" align="center" component="p">
                Boost your APR in the <strong>MIM Machine</strong>. Increase MAX COMPOUND BONUS in <strong>MIM Star Miner</strong>.  
                </Typography>
                <Typography variant="body2" align="center" component="p">
                Please Read: Section 2 of <a href='#info'>How it Works</a> 
                </Typography>
            </Box>
            <Grid container spacing={1} direction="row" justifyContent="space-evenly">
                <Grid item lg={3}>
                    <Card className={ classes.card }>
                    <CardHeader
                        className={ classes.head }
                        title="Bronze Wizard"
                        subheader={<Typography className={ classes.cardSubheader }>+182.5% APR Boost</Typography> }
                    />
                    <Box 
                      component="img"
                      alt="MIM Machine Logo"
                      src={bronzeWiz}
                      height="auto"
                      width="80%"
                    />
                    <Box mb={1} mt={7}>
                        <Typography variant='body1' component='h6' align='center'>
                            Total Minted: {total_bronze_wiz_minted}/{maxWizCount}
                        </Typography>
                    </Box>
                    <Box className={classes.cardActionArea}>
                        <Button className={ classes.mintBtn } variant="contained" color="primary" fullWidth onClick={() => handleApproveBUSD("100")}>
                            APPROVE 100 MIM
                        </Button>
                        <Button className={ classes.bronzeBtn } variant="contained" fullWidth onClick={() => handleMintWizard(1)}>
                            MINT WIZARD
                        </Button>
                    </Box>
                    </Card>
                </Grid>
                <Grid item lg={3}>
                    <Card className={ classes.card }>
                    <CardHeader
                        className={ classes.head }
                        title="Silver Wizard"
                        subheader={<Typography className={ classes.cardSubheader }>+365% APR Boost</Typography> }
                    />
                    <Box 
                      component="img"
                      alt="MIM Machine Logo"
                      src={silverWiz}
                      height="auto"
                      width="80%"
                    />
                    <Box mb={1} mt={7}>
                        <Typography variant='body1' component='h6' align='center'>
                            Total Minted: {total_silver_wiz_minted}/{maxWizCount} 
                        </Typography>
                    </Box>
                    <Box className={classes.cardActionArea}>
                        <Button className={ classes.mintBtn } variant="contained" color="primary" fullWidth onClick={() => handleApproveBUSD("200")}>
                        APPROVE 200 MIM
                        </Button>
                        <Button className={ classes.silverBtn } variant="contained" fullWidth onClick={() => handleMintWizard(2)}>
                            MINT WIZARD
                        </Button>
                    </Box>
                    </Card>
                </Grid>
                <Grid item lg={3}>
                    <Card className={ classes.card }>
                    <CardHeader
                        className={ classes.head }
                        title="Gold Wizard"
                        subheader={<Typography className={ classes.cardSubheader }>+547.5% APR Boost</Typography> }
                    />
                    <Box 
                      component="img"
                      alt="MIM Machine Logo"
                      src={goldWiz}
                      height="auto"
                      width="80%"
                    />
                    <Box mb={1} mt={7}>
                        <Typography variant='body1' component='h6' align='center'>
                            Total Minted: {total_gold_wiz_minted}/{maxWizCount}
                        </Typography>
                    </Box>
                    <Box className={classes.cardActionArea}>
                        <Button className={ classes.mintBtn } variant="contained" color="primary" fullWidth onClick={() => handleApproveBUSD("300")}>
                            APPROVE 300 MIM
                        </Button>
                        <Button className={ classes.goldBtn } variant="contained" fullWidth onClick={() => handleMintWizard(3)}>
                            MINT WIZARD
                        </Button>
                    </Box>
                    </Card>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default MintNFT;