import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { constants, utils, BigNumber } from "ethers";

import { useGetTokenPrice, useTotalUsers, useTotalSupply, useLimitSupply, useAvailableSupply } from '../hooks/statsHooks';

const textColorContrastText = "#3494E6"
const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "#4f7eff",
          padding: theme.spacing(4, 8, 6),
          color: theme.palette.getContrastText(textColorContrastText)
      }
    }
});

function Stats() {
    const classes = useStyles();

    const tokenPrice = useGetTokenPrice();
    const totalUsers = useTotalUsers();
    const totalSupply = useTotalSupply(); // total circulating supply
    const limitSupply = useLimitSupply(); // total supply
    const availableSupply = useAvailableSupply();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" justifyContent="center" alignItems="flex-start">
                <Grid container item>
                    <Grid item>
                        <Typography variant='h2'>
                            <strong>MIM MACHINE</strong> 
                        </Typography>
                        <Typography variant='h6' component='h5'>
                            Stake MIM (Magic Internet Money) to earn POTION to stake and sell for MIM. Use the power of the Wizard to earn more POTION.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={5} lg={2} md={2}>
                        <Typography variant='subtitle1' component='h5'>
                            POTION PRICE
                        </Typography>
                        <Typography variant='h6' component='h5'>
                            {tokenPrice ? parseFloat(utils.formatEther(tokenPrice)).toFixed(4) : "..."}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} lg={2} md={4}>
                        <Typography variant='subtitle1' component='h5'>
                            TOTAL USERS INVESTED
                        </Typography>
                        <Typography variant='h6' component='h5'>
                            {totalUsers ? totalUsers.toString() : "..."}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={5}>
                        <Divider/>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={2}>
                        <Typography variant='subtitle1' component='h5'>
                            TOTAL SUPPLY
                        </Typography>
                        <Typography variant='h6' component='h5'>
                            {limitSupply ? utils.formatEther(limitSupply).slice(0, -2) : "..."}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={2}>
                        <Typography variant='subtitle1' component='h5'>
                            CIRCULATING SUPPLY
                        </Typography>
                        <Typography variant='h6' component='h5'>
                            {totalSupply ? parseFloat(utils.formatEther(totalSupply.toString())).toFixed() : "..."}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={2}>
                        <Typography variant='subtitle1' component='h5'>
                            AVAILABLE SUPPLY
                        </Typography>
                        <Typography variant='h6' component='h5'>
                            {availableSupply ? parseFloat(utils.formatEther(availableSupply.toString())).toFixed() : "..."}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Stats;