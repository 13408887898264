import { useEthers, useContractFunction, useContractCall } from "@usedapp/core";

import {
  bep_20_contract,
  bep_20_interface,
  bep_20_address,

  mim_machine_interface,
  mim_machine_address,
  mim_machine_contract,

  wizard_of_mim_interface,
  wizard_of_mim_address,
  wizard_of_mim_contract
} from '../constants/contracts';

export function useGetApyM() {
    const [apy] = useContractCall({
      abi: mim_machine_interface,
      address: mim_machine_address,
      method: "getAPY_M",
      args: [],
    }) ?? [];
    return apy;
}

export function useGetUserBUSDStaked() {
  const { account } = useEthers();
  const [busdStaked] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getUserBUSDStaked",
    args: [account],
  }) ?? [];
  return busdStaked;
}


export function useTotalBUSDStaked() {
  const [totalBusdStaked] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "totalBUSDStaked",
    args: [],
  }) ?? [];
  return totalBusdStaked;
}

export function useGetUserUnclaimedTokens_M() {
  const { account } = useEthers();
  const [unclaimedTokenM] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getUserUnclaimedTokens_M",
    args: [account],
  }) ?? [];
  return unclaimedTokenM;
}

export function useApproveBUSD(){
  const { state, send, events, resetState } = useContractFunction(bep_20_contract, "approve");
  return { state, send, events, resetState };
}

export function useAllowanceBUSD() {
  const { account } = useEthers();
  const [allowance] = useContractCall({
    abi: bep_20_interface,
    address: bep_20_address,
    method: "allowance",
    args: [account, mim_machine_address], // owner, spender
  }) ?? [];
  return allowance;
}

export function useStakeBUSD(){
  const { state, send, events, resetState } = useContractFunction(mim_machine_contract, "stakeBUSD");
  return { state, send, events, resetState };
}

export function useClaimToken_M(){
  const { state, send, events, resetState } = useContractFunction(mim_machine_contract, "claimToken_M");
  return { state, send, events, resetState };
}

export function useBalanceOfBUSD() {
  const { account } = useEthers();
  const [allowance] = useContractCall({
    abi: bep_20_interface,
    address: bep_20_address,
    method: "balanceOf",
    args: [account], 
  }) ?? [];
  return allowance;
}

export function useUnStakeToken(){
  const { state, send, events, resetState } = useContractFunction(mim_machine_contract, "unStakeToken");
  return { state, send, events, resetState };
}


export function useGetUserUnclaimedTokens_T() {
  const { account } = useEthers();
  const [unclaimedTokenT] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getUserUnclaimedTokens_T",
    args: [account],
  }) ?? [];
  return unclaimedTokenT;
}

export function useClaimToken_T(){
  const { state, send, events, resetState } = useContractFunction(mim_machine_contract, "claimToken_T");
  return { state, send, events, resetState };
}

export function useBalanceOfToken() {
  const { account } = useEthers();
  const [allowance] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "balanceOf",
    args: [account], 
  }) ?? [];
  return allowance;
}

export function useGetAPY_T() {
  const [apyT] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getAPY_T",
    args: [], 
  }) ?? [];
  return apyT;
}

export function useGetUserTokenStaked() {
  const { account } = useEthers();
  const [getUserTokenStaked] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getUserTokenStaked",
    args: [account], 
  }) ?? [];
  return getUserTokenStaked;
}

export function useTotalTokenStaked() {
  const [totalTokenStaked] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "totalTokenStaked",
    args: [], 
  }) ?? [];
  return totalTokenStaked;
}

export function useGetUserTimeToUnstake() {
  const { account } = useEthers();
  const [getUserTimeToUnstake] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getUserTimeToUnstake",
    args: [account],
  }) ?? [];
  return getUserTimeToUnstake;
}

export function useStakeToken(){
  const { state, send, events, resetState } = useContractFunction(mim_machine_contract, "stakeToken");
  return { state, send, events, resetState };
}

export function useGetMaxWizLevelOfUser() {
  const { account } = useEthers();
  const [getMaxWizLevelOfUser] = useContractCall({
    abi: wizard_of_mim_interface,
    address: wizard_of_mim_address,
    method: "getMaxWizLevelOfUser",
    args: [account], 
  }) ?? [];
  return getMaxWizLevelOfUser;
}



export function useGetTokenSoldToday() {
  const [getTokenSoldToday] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getTokenSoldToday",
    args: [], 
  }) ?? [];
  return getTokenSoldToday;
}

export function useGetTokenAvailableToSell() {
  const [getTokenAvailableToSell] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getTokenAvailableToSell",
    args: [], 
  }) ?? [];
  return getTokenAvailableToSell;
}

export function useGetTimeToNextDay() {
  const [getTimeToNextDay] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getTimeToNextDay",
    args: [], 
  }) ?? [];
  return getTimeToNextDay;
}

export function useSellToken(){
  const { state, send, events, resetState } = useContractFunction(mim_machine_contract, "sellToken");
  return { state, send, events, resetState };
}

export function useGetTokenPrice() {
  const [tokenPrice] = useContractCall({
    abi: mim_machine_interface,
    address: mim_machine_address,
    method: "getTokenPrice",
    args: [],
  }) ?? [];
  return tokenPrice;
}
